import './App.scss';
import logo from './images/cuterus.png';

function App() {
  return (
    <div className="App">
      <header className="App-header d-flex headline">
        Ask Dr Skaaning
      </header>
      <section className="container justify-content-start d-flex mt-5 flex-column mb-5">
        <div className="w-100 justify-content-center mb-5">
          <img src={logo} className="w-25" alt="menopause for thought logo" />
          <p class="logotext">
            (Meno)pause for Thought
          </p>
        </div>
        <ul className="list-group w-100 justify-content-start me-3">
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.jostrust.org.uk">Jo's Cervical Cancer Trust</a>
              <small>All you need to know about cervical screening and cervical cancer.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://balance-menopause.com/uploads/2021/10/Menopause-Symptoms-Questionnaire-1.pdf">Menopause Symptoms Questionaire</a>
              <small>Helpful symptom checker that can be downloaded or completed via the balance menopause app.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.womens-health-concern.org/help-and-advice/factsheets/">Women's Health Concern</a>
              <small>The patient facing arm of the British Menopause Society (BMS).</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.balance-menopause.com">Balance Menopause</a>
              <small>Louise Newson's website full of useful resources.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.menopausematters.co.uk">Menopause Matters</a>
              <small>A fantastic site with multiple resources, developed by Dr. Heather Currie MBE from Dumfries and Galloway.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.daisynetwork.org">Daisy Network</a>
              <small>A website for women with premature ovarian insufficiency (POI - Menopause &lt; 40 years)</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.menopausecafe.net">Menopause Cafe</a>
              <small>At a Menopause Café people, often strangers, gather to eat cake, drink tea and discuss menopause.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.queermenopause.com/resources">Queer / LGBTQIA+ Menopause</a>
              <small>Menopause resources for the queer community.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://rockmymenopause.com">Rock my Menopause</a>
              <small>Menopause information will empower you with the information you need to become menopause aware to talk to your GP and loved ones with confidence.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://squeezyapp.com">Squeezy App</a>
              <small>Squeezy is a multi-award winning app supporting people with their pelvic floor muscle exercise programmes.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.nhsaaa.net/allied-health-professionals-ahps/physiotherapy/women-s-and-pelvic-health-physiotherapy/">NHS Ayrshire and Arran</a>
              <small>General information about menopause from the NHS.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.nhsaaa.net/media/9155/2020-05-21-app-health-weight-ayrshire-app-leaflet.pdf">Healthy Weight Ayrshire</a>
              <small>NHS Ayrshire and Arran app, to help you maintain a healthy weight.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://menopausesupport.co.uk">Menopause Support</a>
              <small>Menopause support offers private menopause consultations for individuals and menopause awareness training for businesses and organisations.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://theros.org.uk/information-and-support/bone-health/nutrition-for-bones/calcium/">Royal Osteoporosis Society</a>
              <small>Learn about osteoporosis and how best to protect your bones.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://eveappeal.org.uk">The Eve Appeal</a>
              <small>The Eve Appeal is the leading UK national charity funding research and raising awareness into the five gynaecological cancers – womb, ovarian, cervical, vulval and vaginal.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://onboarding.trydaylight.com/daylight/nhs/144#1/1">The Daylight App</a>
              <small>The worry and anxiety treatment funded by the NHS Scotland.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://onboarding.sleepio.com/sleepio/nhs/120#1/1">The Sleepio App</a>
              <small>The sleep treatment funded by NHS Scotland.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://labialibrary.org.au">The Labia Library</a>
              <small>The Labia Library is about showing you that, just like any other part of the body, labia come in all shapes and sizes.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.pcds.org.uk/patient-info-leaflets/vulval-care">Primary Care Dermatology Society - Vulval care</a>
              <small>A useful guide to caring for your vulva.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.fpa.org.uk/professionals/resources/">Family Planning Association Contraception information leaflets</a>
              <small>Their range of contraceptive booklets answer commonly asked questions about every method of contraception available in the UK. </small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.contraceptionchoices.org">Contraception Choices</a>
              <small>There are many types of contraception available and none are perfect.<br />The Contraception Choices website provides honest information to help weigh up the pros and cons.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.sayanaanswers.co.uk">Sayana Press</a>
              <small>Sayana Press is a form of contraceptive for women that you can easily inject at home.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://shayr.com/services/contraception/long-term-methods/">Sexual Health Ayrshire</a>
              <small>Sexual Health Ayrshire offers long lasting contraception options such as the copper and medicated coils and Implants. They also provide options like the contraceptive injection and patch which last between 1 and 3 months.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.youtube.com/watch?v=XHRYE2FsXmc&t=5s">YouTube Coil Video</a>
              <small>If you would like to have an IUD/IUS fitted, it is strongly recommend that you watch this short film, as it gives you all the information you need about intra-uterine methods.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.educationsupport.org.uk/resources/for-organisations/guides/supporting-staff-experiencing-perimenopause-and-menopause-a-guide-for-all-school-leaders/">Education Support: A guide for all school leaders</a>
              <small>This guide aims to help your school retain valuable team members and ensure they are well supported as they experience perimenopause and menopause.</small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon ovaries"></i>
            <div className="d-flex flex-column">
              <a href="https://www.educationsupport.org.uk/resources/for-individuals/guides/menopause-in-the-education-workplace/">Menopause in the education workplace</a>
              <small>This guide looks at how teachers and education staff can look after themselves and their colleagues and what schools can do to support staff effectively. </small>
            </div>
          </li>
          <li className="list-group-item d-flex">
            <i className="neon-icon womb"></i>
            <div className="d-flex flex-column">
              <a href="https://www.equalityhumanrights.com/guidance/menopause-workplace-guidance-employers?return-url=https%3A%2F%2Fwww.equalityhumanrights.com%2Fsearch%3Fkeys%3DMenopause%2Bworkplace">Menopause in the workplace: Guidance for employers</a>
              <small>These resources are designed to help employers understand their legal obligations in relation to supporting workers experiencing menopausal symptoms.</small>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default App;
